
function observerCallBack(entries, observer){
    console.log("i was triggered")
    entries.forEach(entry => {
        console.log("i was triggered")
        if (!entry.target.classList.contains("offset-drop") && entry.intersectionRatio > 0.2){
            console.log("i was triggered")
            const arrow = document.getElementById("down-icon")
            arrow.classList.add("hide")
            entry.target.classList.add("offset-drop")
        }
      });
}



document.addEventListener("DOMContentLoaded", ()=>{
    const panel = document.getElementById("first_panel")
    console.log("i was triggered1")
    const observer = new IntersectionObserver(
        observerCallBack, {
            root: null,
            rootMargin: '100px',
            threshold: 0.2
        }
    )
    observer.observe(panel)
})
