import("bootstrap")

import './index.scss'
import './images/1920px-MetroCard.SVG.png'
import './images/APP.png'
import './images/business-studies-1024x683.png'
import './images/green-tick.svg'
import './images/ICONS.png'
import './images/quality.jpeg'
import './images/service1.jpeg'
import './images/service2.jpeg'
import './images/service3.jpeg'
import './images/1920px-MetroCard.SVG.png'
import './images/the-business-benefits-of-complementary-partnerships-open-graph.png'
import './images/universal_logo.png'
import './images/the-business-benefits-of-complementary-partnerships-open-graph.png'
import './images/work-from-home-featured-02.png'
import './images/Universal_Benefits_Administration_Brochure.png'
import './images/APP_STORE.png'
import './images/GOOGLE_PLAY.png'
import './images/pdf.svg'
import './images/phone.svg'
import './images/email.png'
import './images/home.svg'
import './images/favicon.png'
import "./home"

